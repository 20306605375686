.countdown-timer {
    display: inline-block;
    height: 6,8rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-timer span {
    margin-left: 5px;
    margin-right: 5px;
}

.two-numbers {
    width: 2ch;
}

.wrapper {
    display:flex;
    flex-wrap:wrap; /* this */
    gap:10px;
 
}

.wrapper_border {
    padding: 1.8rem;
    border: 4px solid black;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF40;
}

.time_style {
    font-size: 5rem;
}

.text_style {
    font-size: 1.4rem;
}

@media only screen and (max-width: 650px) {
    .wrapper_border {
        flex-direction: column;
    }
}